import React from 'react'
import { Redirect, navigate } from '@reach/router'
import { Auth } from 'aws-amplify'

import { AppUser } from '../Auth'
import { AuthForm, Password } from '../Forms'

class NewPassword extends React.Component {
  state = {
    // challengeInfo: null,
    password: ``,
    error: ``,
    loading: false,
  }

  //   componentDidMount() {
  //     const { getChallengeInfo } = AppUser
  //     const challengeInfo = getChallengeInfo();
  //     console.log(challengeInfo);

  //     if (!challengeInfo) navigate('/signin')

  //     this.setState({ challengeInfo })
  //   }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
      error: '',
    })
  }

  setPassword = async e => {
    const { setUser, getChallengeInfo } = AppUser

    e.preventDefault()
    const { password } = this.state
    try {
      const challengeInfo = getChallengeInfo()
      this.setState({ loading: true })
      await Auth.completeNewPassword(
        challengeInfo,
        password
      )
      const user = await Auth.currentAuthenticatedUser()
      const userInfo = {
        ...user.attributes,
        username: user.username,
      }
      setUser(userInfo)
      this.setState({ loading: false })
      navigate('/home')
    } catch (err) {
      this.setState({ error: err, loading: false })
      console.log('error...: ', err)
    }
  }

  render() {
    const { getChallengeInfo } = AppUser
    if (!getChallengeInfo()) {
      return <Redirect to="/signin" noThrow />
    }
    return (
      <AuthForm title="Create a new password" error={this.state.error}>
        <Password
          handleUpdate={this.handleUpdate}
          password={this.state.password}
          autoComplete="on"
        />
        <button
          onClick={e => this.setPassword(e)}
          type="submit"
          className="btn btn-primary btn-block"
          disabled={this.state.loading}
        >
          {this.state.loading ? null : 'Set Password'}
          {this.state.loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </button>
      </AuthForm>
    )
  }
}

export default NewPassword
