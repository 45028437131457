import React from 'react'
import { Storage } from 'aws-amplify'
import FolderIcon from '@material-ui/icons/Folder'
import DescriptionIcon from '@material-ui/icons/Description'
import { AppUser } from '../Auth'

class s3FileList extends React.Component {
  state = {
    items: null,
    root: 'uploads/',
    user: null,
  }

  componentDidMount() {
    this.getFiles()
    this.setState({
      user: AppUser.getCurrentUser(),
    })
  }

  getFiles = () => {
    Storage.list(this.state.root, { level: 'public' })
      .then((res) => {
        const mappedItems = new Map()
        res.forEach((item) => {
          if (item.key !== this.state.root) {
            item.displayName = item.key.replace(this.state.root, '')
            item.type =
              item.key[item.key.length - 1] === '/' ? 'folder' : 'file'
            if (item.type === 'folder') {
              item.displayName = item.displayName.slice(
                0,
                item.displayName.indexOf('/') + 1
              )
              item.key = this.state.root + item.displayName
            }
            if (item.type !== 'file' || !item.displayName.includes('/')) {
              mappedItems.set(item.displayName, item)
            }
          }
        })
        const items = [...mappedItems.values()]
        this.setState({ items })
      })
      .catch((err) => console.log(err))
  }

  checkFileExists = (name) => {
    for (let item of this.state.items) {
      if (item.key === name) {
        return true
      }
    }
    return false
  }

  newUploadEvent = () => {
    this.setState({ items: null })
    this.getFiles()
  }

  getRoot = () => {
    return this.state.root
  }

  itemOnClick = (item) => {
    if (item.type === 'file') {
      Storage.get(item.key, { download: true })
        .then((res) => {
          const binaryData = []
          binaryData.push(res.Body)
          const downloadLink = document.createElement('a')
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: res.Body.type })
          )
          downloadLink.setAttribute('download', item.displayName)
          document.body.appendChild(downloadLink)
          downloadLink.click()
          return downloadLink
        })
        .catch((err) => console.log(err))
    } else if (item.type === 'folder') {
      this.setState(
        {
          root: item.key,
        },
        () => this.getFiles()
      )
    }
  }

  removeItem(item) {
    if (
      window.confirm(
        'Are you sure you want to permanently delete this item? If a folder contains any items, you must first delete those items.'
      )
    ) {
      Storage.remove(item.key)
        .then(() => this.getFiles())
        .catch((err) => console.log(err))
    }
  }

  onFolderClicked = (folder) => {
    const folderIndex = this.state.root.indexOf(folder)
    const newRoot = this.state.root.slice(0, folderIndex) + folder + '/'
    this.setState(
      {
        root: newRoot,
      },
      () => this.getFiles()
    )
  }

  render() {
    if (this.state.items) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              padding: '12px 0',
              borderBottom: '1px solid lightgray',
            }}
          >
            {this.state.root.split('/').map((folder, index) => {
              if (folder !== '') {
                return (
                  <div
                    key={folder}
                    onClick={() => this.onFolderClicked(folder)}
                    style={{ cursor: 'pointer' }}
                  >
                    {folder}
                    <span style={{ margin: '0 4px' }}>
                      {this.state.root.split('/').length - 1 !== index + 1
                        ? '>'
                        : ''}
                    </span>
                  </div>
                )
              }
            })}
          </div>
          {this.state.items.length ? (
            this.state.items.map((item) => {
              return (
                <div
                  style={{ display: 'flex', margin: '12px 0' }}
                  key={item.key}
                >
                  {item.type === 'folder' ? (
                    <FolderIcon
                      style={{ alignSelf: 'center', marginRight: '12px' }}
                    />
                  ) : (
                    <DescriptionIcon
                      style={{ alignSelf: 'center', marginRight: '12px' }}
                    />
                  )}
                  <div
                    style={{
                      flex: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      this.itemOnClick(item)
                    }}
                  >
                    {item.displayName}
                  </div>
                  {this.state.user.email === 'josh@hinge.dev' && (
                    <button
                      style={{ border: '0px' }}
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => this.removeItem(item)}
                    >
                      X
                    </button>
                  )}
                </div>
              )
            })
          ) : (
            <div style={{ margin: '12px 0' }}>No files avaliable</div>
          )}
        </div>
      )
    } else {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            style={{ height: '100px', width: '100px', margin: '50px' }}
          />
        </div>
      )
    }
  }
}

export default s3FileList
