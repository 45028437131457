import React from 'react'
import { AppContent } from '../Layout'
import { FileUpload, FileList } from '../File'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.fileList = React.createRef()
  }

  // handleCheck(file) {
  //   return this.fileList.current.checkFileExists(file.name);
  // }

  render = () => {
    return (
      <div className="container-login100">
        <AppContent>
          <h1>Documents</h1>
          <FileUpload
            checkFileExists={(filePath) =>
              this.fileList.current.checkFileExists(filePath)
            }
            newUploadEvent={() => this.fileList.current.newUploadEvent()}
            getRoot={() => this.fileList.current.getRoot()}
          />
          <FileList ref={this.fileList} />
        </AppContent>
      </div>
    )
  }
}

export default Home
