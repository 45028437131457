import React from 'react'
import HeaderPic from '../../assets/images/COVER_orange.jpeg'
import WakePic from '../../assets/images/HIGHLIGHT_wake.jpeg'
import SquareArial from '../../assets/images/SQUARE_arial.jpeg'
import SquareDockBoat from '../../assets/images/SQUARE_dock-boat.jpeg'
import SquareDock from '../../assets/images/SQUARE_dock.jpeg'
import SquareOrange from '../../assets/images/SQUARE_orange.jpeg'
import SquareWakeTrees from '../../assets/images/SQUARE_wake_trees.jpeg'
import SquareWake from '../../assets/images/SQUARE_wake.jpeg'
import { Layout } from '../Layout'
import { ContactForm, Email, Name, Message } from '../Forms'
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
  FaTwitterSquare,
  FaPinterestSquare,
} from 'react-icons/fa'
import { navigate } from '@reach/router'
import { API } from 'aws-amplify'
import { AppUser } from '../Auth'

class IndexPage extends React.Component {
  state = {
    email: '',
    name: '',
    message: '',
    error: '',
    loading: false,
    lastUpdated: null,
    airTemp: null,
    waterTemp: null,
    averageWindSpeed: null,
  }

  async componentDidMount() {
    const data = await API.get('api58df20de', '/weather', {})
    this.setState({
      airTemp: data.airTemp,
      lastUpdated: data.lastUpdated,
      waterTemp: data.waterTemp,
      averageWindSpeed: data.averageWindSpeed,
    })
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      error: '',
    })
  }

  contactScroll = (e) => {
    const mainNavHeight = document.querySelector(`nav`).offsetHeight
    const item = document.querySelector(`#contact-form`).offsetTop

    window.scrollTo({
      top: item - mainNavHeight,
      behavior: 'smooth',
    })
  }

  submitContact = async (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    const { email, name, message } = this.state

    const data = await API.post('apicontactform', '/contact', {
      body: {
        email,
        name,
        message,
      },
    })

    this.setState({ email: '', name: '', message: '', loading: '' })
    return data
  }

  waiverLink = (e) => {
    navigate('https://waiver.suntenlakes.com/form/sunten-release')
  }

  render = () => {
    const { isLoggedIn } = AppUser

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <header
          style={{
            backgroundImage: `url(${HeaderPic})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100%',
            width: '100%',
            height: '525px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Layout isUserNav={isLoggedIn()} page="landing">
            <div
              style={{
                height: 'calc(525px - 56px)',
                backgroundImage:
                  'linear-gradient(transparent, rgba(0, 0, 0, 0.0))',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
              }}
            >
              <h1>Sunten Lakes</h1>
              {/* <h3>Your messages goes here</h3> */}
              <div style={{ margin: '24px' }}>
                <button
                  onClick={(e) => this.contactScroll(e)}
                  type="submit"
                  className="btn btn-primary btn-block font-weight-bold"
                  // disabled={state.loading}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </Layout>
        </header>

        <main style={{ flex: 'auto' }}>
          <section
            style={{
              // height: '500px',
              padding: '36px 0',
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '390px',
                padding: '1.25em',
              }}
            >
              <h3>Already here?</h3>
              <p>
                Ready to have some fun, but need to sign a waiver? Click the
                button below and fill out the form!
              </p>
              <button
                onClick={(e) => this.waiverLink(e)}
                type="submit"
                className="btn btn-outline-primary btn-block"
              >
                Sign a Waiver
              </button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '800px',
                padding: '1.25em',
              }}
            >
              <img
                src={WakePic}
                alt="wakeboarder"
                style={{
                  width: '100%',
                  height: 'auto',
                  margin: '0',
                  boxShadow: '0 10px 24px rgba(0,0,0,.2)',
                }}
              />
            </div>
          </section>

          <section
            style={{
              backgroundColor: '#eee9e3',
              padding: '36px 0',
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '550px',
                padding: '1.25em',
              }}
            >
              <h2 style={{ textAlign: 'center' }}>Conditions</h2>
              {!this.state.lastUpdated ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ height: '96px', width: '96px', margin: '50px' }}
                  />
                </div>
              ) : (
                <div>
                  <p>{this.state.lastUpdated}&nbsp;</p>
                  <p>Air temperature: {this.state.airTemp}</p>
                  <p>Water temperature: {this.state.waterTemp}</p>
                  <p>Average wind speed: {this.state.averageWindSpeed}</p>
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '500px',
                padding: '1.25em',
              }}
            >
              <h2 style={{ marginBottom: '36px' }}>Events</h2>
              <div className="event-table">
                <div className="event">
                  <div>Date</div>
                  <div>Event</div>
                </div>

                <div className="event">
                  <div>06-26-2021</div>
                  <div>SunTen Starter Slalom Tournament</div>
                </div>

                <div className="event">
                  <div>07-10-2021</div>
                  <div>SunTen Slalom 2</div>
                </div>

                <div className="event">
                  <div>08-21-2021</div>
                  <div>SunTen August Slalom</div>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
            }}
          >
            <div className="gallery">
              <div className="gallery__item--1">
                <img src={SquareOrange} alt="lake" className="gallery__img" />
              </div>
              <div className="gallery__item--2">
                <img src={SquareWake} alt="lake" className="gallery__img" />
              </div>
              <div className="gallery__item--3">
                <img src={SquareArial} alt="lake" className="gallery__img" />
              </div>
              <div className="gallery__item--4">
                <img src={SquareDock} alt="lake" className="gallery__img" />
              </div>
              <div className="gallery__item--5">
                <img src={SquareDockBoat} alt="lake" className="gallery__img" />
              </div>
              <div className="gallery__item--6">
                <img
                  src={SquareWakeTrees}
                  alt="lake"
                  className="gallery__img"
                />
              </div>
            </div>
          </section>
        </main>

        <footer
          id="contact-form"
          style={{
            background: 'none #1b1615',
            padding: '36px 0',
            color: 'white',
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ width: '390px' }}>
            <ContactForm title="Contact us" error={this.state.error}>
              <Email
                handleUpdate={this.handleUpdate}
                email={this.state.email}
                autoComplete="on"
              />
              <Name
                handleUpdate={this.handleUpdate}
                name={this.state.name}
                autoComplete="on"
              />
              <Message
                handleUpdate={this.handleUpdate}
                message={this.state.message}
                autoComplete="on"
              />
              <button
                onClick={(e) => this.submitContact(e)}
                type="submit"
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading ? null : 'Send'}
                {this.state.loading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </ContactForm>
          </div>
          <div style={{ width: '390px', padding: '1.25rem' }}>
            <h2>SunTen, Inc</h2>
            <p>1779 N Indian Mountain Road</p>
            <p>Rush Valley, Utah 84069</p>
            <p>Unted States</p>

            {/* <h3 style={{ padding: '34px 0 12px 0' }}>Social:</h3> */}
            {/* <div>
              <div style={{ display: 'flex' }}>
                <a
                  className="social-icons-index"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://facebook.com/"
                >
                  <FaFacebookSquare size={'2em'} />
                </a>
                <a
                  className="social-icons-index"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://facebook.com/"
                >
                  <FaInstagramSquare size={'2em'} />
                </a>
                <a
                  className="social-icons-index"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://facebook.com/"
                >
                  <FaYoutubeSquare size={'2em'} />
                </a>
                <a
                  className="social-icons-index"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://facebook.com/"
                >
                  <FaTwitterSquare size={'2em'} />
                </a>
                <a
                  className="social-icons-index"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://facebook.com/"
                >
                  <FaPinterestSquare size={'2em'} />
                </a>
              </div>
            </div> */}
          </div>
        </footer>
      </div>
    )
  }
}

export default IndexPage
