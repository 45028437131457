import React from 'react'
import { Storage } from 'aws-amplify'

class s3FileUpload extends React.Component {
  state = {
    loading: false,
  }
  onChange(e) {
    const root = this.props.getRoot()
    const file = e.target.files[0]
    const filePath = root + file.name
    const fileExists = this.props.checkFileExists(filePath)

    if (
      (fileExists &&
        window.confirm('This file already exists, overwrite it?')) ||
      !fileExists
    ) {
      Storage.put(filePath, file, {
        contentType: file.type,
      })
        .then((result) => {
          this.props.newUploadEvent()
        })
        .catch((err) => console.log(err))
    }

    e.target.value = ''
  }

  addFolder() {
    let folderName = window.prompt('What do you want to name the folder?')
    if (folderName) {
      folderName = folderName.replace(/\//g, '_')
      const root = this.props.getRoot()
      const folderPath = root + folderName + '/'
      const folderExists = this.props.checkFileExists(folderPath)
      if (!folderExists) {
        Storage.put(folderPath)
          .then((result) => {
            this.props.newUploadEvent()
          })
          .catch((err) => console.log(err))
      }
    }
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <div className="custom-file">
          <input
            id="inputFile"
            className="custom-file-input"
            type="file"
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            onChange={(evt) => this.onChange(evt)}
            hidden
          />
          <label
            style={{ cursor: 'pointer' }}
            className="custom-file-label"
            htmlFor="inputFile"
          >
            Upload a file
          </label>
        </div>
        <button
          onClick={(e) => this.addFolder(e)}
          type="submit"
          className="btn btn-primary btn-block"
          disabled={this.state.loading}
          style={{ width: '150px', marginLeft: '5px' }}
        >
          {this.state.loading ? null : 'Add Folder'}
          {this.state.loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </button>
      </div>
    )
  }
}

export default s3FileUpload
