import React from 'react'
import { AppContent } from '../Layout'
import { DataGrid } from '@material-ui/data-grid'

import { API, Auth } from 'aws-amplify'
import { Storage } from 'aws-amplify'

class Home extends React.Component {

  state = {
    rows: [],
    loading: false,
    filterValue: '',
  }

  columns = [
    { field: 'id', headerName: 'ID', width: 130, hide: true },
    {
      field: 'date',
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
      width: 130,
    },
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'dependents', headerName: 'Dependents', width: 300 },
    { field: 's3Key', headerName: 'S3 Key', width: 300, hide: true },
  ]
  debounce = (func, wait, immediate) => {
    var timeout
    return function () {
      arguments[0].persist()
      var context = this,
        args = arguments
      var later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      var callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    })

    const data = await API.get('waiverapi', '/items/waiver', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    })

    const rowData = data.map((waiver, index) => {
      const tableData = {
        id: index,
        date: new Date(waiver.sk).toLocaleDateString(),
        name: waiver.data.participantName,
        s3Key: waiver.data.waiverS3Key,
        dependents: waiver.data.dependentQuestion
          ? waiver.data.dependentNames
          : undefined,
      }
      return tableData
    })

    this.setState({
      rows: rowData,
      loading: false,
    })
  }

  onTextChange = this.debounce((evt) => {
    this.setState({
      filterValue: evt.target.value || '',
    })
  }, 200)

  downloadFile = (item) => {
    Storage.get(item.row.s3Key.replace('public/', ''), { download: true })
      .then((res) => {
        const binaryData = []
        binaryData.push(res.Body)
        const downloadLink = document.createElement('a')
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: res.Body.type })
        )
        downloadLink.setAttribute('download', item.row.name + ' Waiver')
        document.body.appendChild(downloadLink)
        downloadLink.click()
        return downloadLink
      })
      .catch((err) => console.log(err))
  }

  render = () => {
    return (
      <div className="container-login100">
        <AppContent>
          <h1>Waivers</h1>
          <div className="form-group">
            <input
              onChange={this.onTextChange}
              name="text"
              type="text"
              // value={email}
              className="form-control"
              id="filterName"
              aria-describedby="filterName"
              placeholder="Filter name"
            />
          </div>
          {!this.state.loading ? (
            <div
              style={{
                width: '100%',
                flex: 'auto',
                maxHeight: '700px',
              }}
            >
              <DataGrid
                rows={this.state.rows}
                columns={this.columns}
                autoPageSize={true}
                disableSelectionOnClick={true}
                disableColumnSelector={true}
                onRowClick={this.downloadFile}
                sortModel={[
                  {
                    field: 'date',
                    sort: 'desc',
                  },
                ]}
                filterModel={{
                  items: [
                    {
                      columnField: 'name',
                      operatorValue: 'contains',
                      value: this.state.filterValue,
                    },
                  ],
                }}
              />
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                style={{ height: '100px', width: '100px', margin: '50px' }}
              />
            </div>
          )}
        </AppContent>
      </div>
    )
  }
}

export default Home
