import React from 'react'
import { Router } from '@reach/router'
import {
  Profile,
  Documents,
  Waivers,
  IndexPage,
  Reset,
  SignIn,
  // SignUp,
  NewPassword
} from '../components/Pages'
import PrivateRoute from '../components/Routes/PrivateRoute'
import PublicRoute from '../components/Routes/PublicRoute'
import Amplify from 'aws-amplify'
import LogRocket from 'logrocket';
import config from '../aws-exports'

const App = () => {
  LogRocket.init('ohdp6l/suntenlake')
  Amplify.configure(config)
  return (
    <Router>
      <PrivateRoute path="/documents" component={Documents} />
      <PrivateRoute path="/waivers" component={Waivers} />
      <PrivateRoute path="/profile" component={Profile} />
      <PublicRoute path="/signin" component={SignIn} />
      <PublicRoute path="/new-password" component={NewPassword} />
      {/* <PublicRoute path="/signup" component={SignUp} /> */}
      <PublicRoute path="/reset" component={Reset} />
      <PublicRoute path="/" component={IndexPage} />
    </Router>
  )
}

export default App
