/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:bad37584-5308-4c08-ba6a-7ee59586620b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_UilAfSwYZ",
    "aws_user_pools_web_client_id": "ee5f881bcabd6q62357b7qinu",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "api58df20de",
            "endpoint": "https://t5h10qwkb3.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        },
        {
            "name": "apicontactform",
            "endpoint": "https://yecl7oc9n4.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        },
        {
            "name": "waiverapi",
            "endpoint": "https://so7j1to7u1.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "sunten123316-master",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "waiverdb-master",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
