import React from 'react'

export function ContactForm({ children, title, error }) {
  return (
      <div style={{ flex: 'auto'}}>
        <form style={{ padding: '1.25rem' }}>
          <h2>{title}</h2>
          {error && (
            <p className="text-danger">
              {error.message ? error.message : error}
            </p>
          )}
          {children}
        </form>
      </div>
  )
}

export function Name({ handleUpdate, name, autoComplete }) {
  return (
    <div className="form-group">
      <label htmlFor="enterName">Name</label>
      <input
        onChange={handleUpdate}
        autoComplete={autoComplete}
        name="name"
        value={name}
        type="text"
        className="form-control"
        placeholder="Name"
        id="enterName"
      />
    </div>
  )
}

export function Email({ handleUpdate, email, autoComplete }) {
  return (
    <div className="form-group">
      <label htmlFor="enterEmailAddress">Email Address</label>
      <input
        onChange={handleUpdate}
        name="email"
        type="email"
        value={email}
        className="form-control"
        autoComplete={autoComplete}
        id="enterEmailAddress"
        aria-describedby="emailHelp"
        placeholder="Enter email"
      />
    </div>
  )
}

export function Message({ handleUpdate, message, autoComplete }) {
  return (
    <div className="form-group">
      <label htmlFor="messageArea">Message</label>
      <textarea
        onChange={handleUpdate}
        autoComplete={autoComplete}
        name="message"
        value={message}
        type="text"
        className="form-control"
        placeholder="Ask us anything.."
        id="messageArea"
      />
    </div>
  )
}
